import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ReactElement, useEffect, useState } from "react";
import {
    AutocompleteInput,
    DateInput,
    FileField,
    FileInput,
    Loading,
    required,
    SimpleForm,
    TextInput
} from "react-admin";
import FormToolbar from "../../../../shared/components/toolbar/FormToolbar";
import useUser from "../../../../shared/hooks/useUsers";
import { User } from "../../../../shared/types/types";
import fetchDataService from "../../../../shared/utilities/fetchs";

interface ImplementationOFActionFormProps {
    edit?: boolean;
    formTitle: ReactElement;
}

const ImplementationOfActionForm = ({ edit, formTitle }: ImplementationOFActionFormProps) => {
    const [loading, setLoading] = useState(true);
    const [responsibleForClousing, setResponsibleForClousing] = useState<User[]>();

    useEffect(() => {
        const { getResponsibleForClosingData } = fetchDataService();
        Promise.all([
            getResponsibleForClosingData(),
        ]).then(([responsibleForClosingResponse]) => {
            setResponsibleForClousing(responsibleForClosingResponse.data);
            setLoading(false);
        }).catch(error => {
            console.error('Error fetching data in Implementation:', error);
            setLoading(false);
        });
    }, []);

    const { dataUser, isLoadingUser } = useUser();

    if (loading) {
        return <Loading />;
    }

    if (isLoadingUser) {
        return <Loading />;
    }

    return (
        <SimpleForm
            toolbar={<FormToolbar edit={edit ? true : undefined} />}
            sx={{ mt: 3, mb: 3 }}
        >
            <Grid container lg={10} lgOffset={1} columnSpacing={2}>
                <Grid xs={12} sx={{ mb: 2 }}>
                    <Typography variant="h6">{formTitle}</Typography>
                </Grid>

                <Grid container md={12}>
                    <Grid xs={12} md={4}>
                        <TextInput
                            source="responsibleForAnalysis.fullName"
                            label="resources.sacop.fields.responsibleForAnalysisId"
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <DateInput
                            source="implementationDate"
                            label="resources.sacop.fields.implementationDate"
                            variant="outlined"
                            validate={required()}
                            helperText={false}
                            fullWidth
                        />
                    </Grid>

                    <Grid xs={12} md={4}>
                        <AutocompleteInput
                            source="responsibleForClosingId"
                            label="resources.sacop.fields.responsibleForClosingId"
                            choices={
                                dataUser
                                    ? dataUser.map((user) => ({
                                        id: user.id,
                                        name: user.fullName,
                                    }))
                                    : []
                            }
                            fullWidth
                        />
                    </Grid>

                    <Grid xs={12} md={6} sx={{ pl: 3 }}>
                        <FileInput
                            source="evidence"
                            label="resources.sacop.fields.evidence">
                            <FileField source="src" title="title" target="_blank" />
                        </FileInput>
                    </Grid>
                </Grid>
            </Grid>
        </SimpleForm>
    );

};

export default ImplementationOfActionForm;