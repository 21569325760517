import axios, { Axios, AxiosResponse } from "axios";
import { Authority, Localization, LossValuation, Motive, Regulation, Sacop, SacopStatus, TypeOfActionEntity, TypeOfGeneratingEntity, User } from "../types/types";
import { Exporter } from "react-admin";

const fetchDataService = () => {
  const urlBase = process.env.REACT_APP_API_BASE_URL;
  const withoutHeaderAuthN = {
    "X-Correlation-Id": "64008734.04828116",
    "X-App-Id": "00e9396e-6c63-4834-8492-a1db4edc6421",
    "Content-Type": "application/json",
  };
  const headerAuthN = {
    Authorization: `Bearer ${localStorage.getItem("auth")?.toString()}`,
    "X-Correlation-Id": "64008734.04828116",
    "X-App-Id": "00e9396e-6c63-4834-8492-a1db4edc6421",
    "Content-Type": "application/json",
  };

  const postData = (url: string, data: any, headers: any): Promise<any> => {
    if (headers == null) {
      return axios.post(url, data, { headers: withoutHeaderAuthN });
    }
    return axios.post(url, data, { headers: headers });
  };

  const putData = (url: string, data: any, headers: any): Promise<any> => {
    if (headers == null) {
      return axios.put(url, data, { headers: withoutHeaderAuthN });
    }
    return axios.put(url, data, { headers: headers });
  };

  const deleteData = (url: string, headers: any): Promise<any> => {
    if (headers == null) {
      return axios.delete(url, { headers: withoutHeaderAuthN });
    }
    return axios.delete(url, { headers: headers });
  }

  const getData = (url: string): Promise<any> => {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("auth")?.toString()}`,
      "X-Correlation-Id": "64008734.04828116",
      "X-App-Id": "00e9396e-6c63-4834-8492-a1db4edc6421",
      "Content-Type": "application/json",
    };
    return axios.get(url, { headers: header });
  };

  /** AUTHN */
  const postLoginUser = (data: any): Promise<any> => {
    return postData(`${urlBase}/api/v1.0/login`, data, null);
  };

  const getPermissionsUser = (): Promise<any> => {
    return getData(`${urlBase}/api/v1.0/claims`);
  };

  /**TEMPLATE*/
  const getTemplateData = (
    urlParams: string,
    data: any = null
  ): Promise<any> => {
    return getData(`${urlBase}/api/v1/template/${urlParams}`);
  };

  /**COMPANY*/
  const getCompanyData = (): Promise<any> => {
    return getData(`${urlBase}/api/v1.0/company`);
  };

  const getNormativeData = (normativeId: string): Promise<any> => {
    return getData(`${urlBase}/api/v1.0/normative/${normativeId}`);
  };

  const getSectorData = (): Promise<any> => {
    return getData(`${urlBase}/api/v1.0/sector`);
  };

  const postChapterTreeData = (
    chapterTreeId: string,
    data: any
  ): Promise<any> => {
    return postData(
      `${urlBase}/api/v1.0/chapterTree/${chapterTreeId}`,
      data,
      headerAuthN
    );
  };

  const postMultipartChapterTreeData = (
    chapterTreeId: string,
    data: any
  ): Promise<any> => {
    const formData = new FormData();
    formData.append(
      "chapterTreeInfo",
      new Blob([JSON.stringify(data.chapterTreeInfo)], {
        type: "application/json",
      })
    );
    data.docFile && formData.append("docFile", data.docFile);
    return postData(
      `${urlBase}/api/v1.0/chapterTree/${chapterTreeId}`,
      formData,
      {
        ...headerAuthN,
        "Content-Type": "multipart/form-data",
      }
    );
  };

  const putChapterTreeData = (
    chapterTreeId: string,
    data: any
  ): Promise<any> => {
    return putData(
      `${urlBase}/api/v1.0/chapterTree/${chapterTreeId}`,
      data,
      headerAuthN
    );
  };

  const putMultipartChapterTreeData = (
    chapterTreeId: string,
    data: any
  ): Promise<any> => {
    const formData = new FormData();
    formData.append(
      "chapterTreeInfo",
      new Blob([JSON.stringify(data.chapterTreeInfo)], {
        type: "application/json",
      })
    );
    data.docFile && formData.append("docFile", data.docFile);
    return putData(
      `${urlBase}/api/v1.0/chapterTree/${chapterTreeId}`,
      formData,
      {
        ...headerAuthN,
        "Content-Type": "multipart/form-data",
      }
    );
  };

  const deleteChapterTreeData = (
    chapterTreeId: string,
    data: any
  ): Promise<any> => {
    return axios.delete(`${urlBase}/api/v1.0/chapterTree/${chapterTreeId}`, {
      data,
      headers: headerAuthN,
    });
  };

  const postChapterRegistryData = (
    data: any
  ): Promise<any> => {
    const formData = new FormData();
    formData.append(
      "chapterId", data.chapterId
    );
    data.document && formData.append("document", data.document);
    return postData(`${urlBase}/api/v1.0/chapterRegistry/`, formData, {
      ...headerAuthN,
      "Content-Type": "multipart/form-data",
    });
  };

  const putRegeneratePassword = (
    userId: string
  ): Promise<any> => {
    return putData(`${urlBase}/api/v1.0/user/${userId}/regenerate`, null, headerAuthN)
  }

  const getMotiveData = (): Promise<AxiosResponse<Motive[]>> => {
    return getData(`${urlBase}/api/v1.0/motive`);
  };

  const getTypeOfGeneratingEntityData = (): Promise<AxiosResponse<TypeOfGeneratingEntity[]>> => {
    return getData(`${urlBase}/api/v1.0/typeOfGeneratingEntity`);
  };

  const getTypeOfActionData = (): Promise<AxiosResponse<TypeOfActionEntity[]>> => {
    return getData(`${urlBase}/api/v1.0/typeOfActionEntity`);
  };
  

  const getResponsibleForAnalysisData = (): Promise<AxiosResponse<User[]>> => {
    return getData(`${urlBase}/api/v1.0/user?filter=%7B%22active%22%3Atrue%7D&range=%5B0%2C25%5D&sort=%5B%22name%22%2C%22DESC%22%5D`);
  }

  const getSacopData = (sacopId: number): Promise<AxiosResponse<Sacop>> => {
    return getData(`${urlBase}/api/v1.0/requestForAction/sacop/${sacopId}`);
  }

  const getAuthorityData = (): Promise<AxiosResponse<Authority[]>> => {
    return getData(`${urlBase}/api/v1.0/authority`);
  }

  const getLocalizationData = (): Promise<AxiosResponse<Localization[]>> => {
    return getData(`${urlBase}/api/v1.0/localization/user?filter=%7B%22active%22%3Atrue%7D`);
  }

  const getExporterData = (): Promise<AxiosResponse<Exporter[]>> => {
    return getData(`${urlBase}/api/v1.0/exporter`);
  }

  const getRegulationData = (): Promise<AxiosResponse<Regulation[]>> => {
    return getData(`${urlBase}/api/v1.0/regulation`);
  }

  const getLossValuationData = (): Promise<AxiosResponse<LossValuation[]>> => {
    return getData(`${urlBase}/api/v1.0/lossValuation`);
  }

  const getResponsibleForImplementationData = (): Promise<AxiosResponse<User[]>> => {
    return getData(`${urlBase}/api/v1.0/user?filter=%7B%22active%22%3Atrue%7D&range=%5B0%2C25%5D&sort=%5B%22name%22%2C%22DESC%22%5D`);
  }

  const getResponsibleForClosingData = (): Promise<AxiosResponse<User[]>> => {
    return getData(`${urlBase}/api/v1.0/user?filter=%7B%22active%22%3Atrue%7D&range=%5B0%2C25%5D&sort=%5B%22name%22%2C%22DESC%22%5D`);
  }

  const getResponsibleForVerificationData = (): Promise<AxiosResponse<User[]>> => {
    return getData(`${urlBase}/api/v1.0/user/responsibleForVerification`);
  }

  const sendSacopToResponsibleForAnalysis = (sacopId: number): Promise<AxiosResponse<Sacop>> => {
    return putData(`${urlBase}/api/v1.0/requestForAction/sendSacopToResponsibleForAnalysis/${sacopId}`, null, headerAuthN);
  }

  const sendSacopToResponsibleForImplementation = (sacopId: number): Promise<AxiosResponse<Sacop>> => {
    return putData(`${urlBase}/api/v1.0/analysisOfShares/sendSacopToResponsibleForImplementation/${sacopId}`, null, headerAuthN);
  }

  const sendSacopToResponsibleForClosing = (sacopId: number): Promise<AxiosResponse<Sacop>> => {
    return putData(`${urlBase}/api/v1.0/implementationOfAction/sendSacopToResponsibleForClosing/${sacopId}`, null, headerAuthN);
  }

  const closeAndSendSacopToResponsibleOfVerification = (sacopId: number): Promise<AxiosResponse<Sacop>> => {
    return putData(`${urlBase}/api/v1.0/closingOfShares/closeAndSendSacopToResponsibleOfVerification/${sacopId}`, null, headerAuthN);
  }

  const getSacopStatusForClosingData = (): Promise<AxiosResponse<SacopStatus[]>> => {
    return getData(`${urlBase}/api/v1.0/sacopStatus/getSacopStatusForClosing`);
  }

  const getSacopStatusForVerificationData = (): Promise<AxiosResponse<SacopStatus[]>> => {
    return getData(`${urlBase}/api/v1.0/sacopStatus/getSacopStatusForVerification`);
  }

  const getSacopStatusForClosingByMasterData = (): Promise<AxiosResponse<SacopStatus[]>> => {
    return getData(`${urlBase}/api/v1.0/sacopStatus/getSacopStatusForClosingByMaster`);
  }

  const getSacopStatusForVerificationByMasterData = (): Promise<AxiosResponse<SacopStatus[]>> => {
    return getData(`${urlBase}/api/v1.0/sacopStatus/getSacopStatusForVerificationByMaster`);
  }

  const getUserInfo = (username: string) => {
    return getData(`${urlBase}/api/v1.0/user/info/${username}`);
  }

  const postSetupMfa = () => {
    return postData(`${urlBase}/api/v1.0/mfa/setup`, null, headerAuthN);
  }

  const postValidateMfaSetup = (code: string) => {
    return postData(`${urlBase}/api/v1.0/mfa/setup/code?code=${code}`, null, headerAuthN);
  }

  const postMfaLoginCode = (code: string) => {
    return postData(`${urlBase}/api/v1.0/mfa/code/validate?code=${code}`, null, headerAuthN);
  }

  const deleteMfa = (userPassword: string) => {
    return deleteData(`${urlBase}/api/v1.0/mfa/delete?userData=${userPassword}`, {
      ...headerAuthN,
    });
  }

  const deleteMfaUsingRecoveryCode = (recoveryCode: string) => {
    return deleteData(`${urlBase}/api/v1.0/mfa/code/recovery?recoveryCode=${recoveryCode}`, {
      ...headerAuthN,
    });
  }

  return {
    postLoginUser,
    getPermissionsUser,
    getTemplateData,
    getCompanyData,
    getSectorData,
    postChapterTreeData,
    postMultipartChapterTreeData,
    putChapterTreeData,
    putMultipartChapterTreeData,
    deleteChapterTreeData,
    getNormativeData,
    postChapterRegistryData,
    putRegeneratePassword,
    getMotiveData,
    getTypeOfGeneratingEntityData,
    getResponsibleForAnalysisData,
    getSacopData,
    getAuthorityData,
    getLocalizationData,
    getExporterData,
    getRegulationData,
    getLossValuationData,
    getResponsibleForImplementationData,
    getResponsibleForClosingData,
    getResponsibleForVerificationData,
    sendSacopToResponsibleForAnalysis,
    sendSacopToResponsibleForImplementation,
    sendSacopToResponsibleForClosing,
    closeAndSendSacopToResponsibleOfVerification,
    getSacopStatusForClosingData,
    getSacopStatusForVerificationData,
    getSacopStatusForClosingByMasterData,
    getSacopStatusForVerificationByMasterData,
    getUserInfo,
    postSetupMfa,
    postValidateMfaSetup,
    postMfaLoginCode,
    deleteMfa,
    deleteMfaUsingRecoveryCode,
    getTypeOfActionData
  };
};

export default fetchDataService;
