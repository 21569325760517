import { Theme, useMediaQuery } from "@mui/material";
import { Fragment } from "react";
import { TextField, DatagridConfigurable, List, SearchInput, useListContext, TopToolbar, FilterButton, ExportButton, SortButton, useGetResourceLabel, TextInput, BooleanInput, DateInput } from "react-admin";
import MobileGrid from "./MobileGrid";

const ListActions = () => (
    <TopToolbar>
        <SortButton fields={['dateRequestAction']} />
        <FilterButton />
        <ExportButton />
    </TopToolbar>
);

const sectorFilters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="dateRequestAction" />, 
];

const TabbedDatagrid = () => {
    const listContext = useListContext();
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );

    return (
        <Fragment>
            {(!isXSmall ? (
                <MobileGrid  {...listContext} />
            ) : (
                <DatagridConfigurable rowClick="edit" >
                    <TextField source="dateRequestAction" />
                </DatagridConfigurable>)
            )
            }
        </Fragment>
    );
}

const AnalysisOfSharesList = () => {
    return (<List
        // filterDefaultValues={ { active: true }}
        sort={{ field: 'dateRequestAction', order: 'DESC' }}
        perPage={25}
        filters={sectorFilters}
        actions={<ListActions />}
    >
        <TabbedDatagrid />
    </List>);
};

export default AnalysisOfSharesList;

