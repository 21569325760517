import { InputLabel, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ReactElement, useEffect, useState } from "react";
import {
    AutocompleteInput,
    BooleanInput,
    DateInput,
    FileField,
    Loading,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    useRecordContext
} from "react-admin";
import FormToolbar from "../../../../shared/components/toolbar/FormToolbar";
import useUser from "../../../../shared/hooks/useUsers";
import { SacopStatus, User } from "../../../../shared/types/types";
import fetchDataService from "../../../../shared/utilities/fetchs";

interface ClosingOfSharesFormProps {
    edit?: boolean;
    formTitle: ReactElement;
    labelEvidence: ReactElement;
}

const ClosingOfSharesForm = ({ edit, formTitle, labelEvidence }: ClosingOfSharesFormProps) => {
    const [loading, setLoading] = useState(true);
    const [responsibleForVerification, setResponsibleForVerification] = useState<User[]>();
    const [sacopStatusClosing, setSacopStatusClosing] = useState<SacopStatus[]>();
    const [showClosingStatusField, setShowClosingStatusField] = useState(false);
    const record = useRecordContext();

    const handleBooleanInput = (event: any) => {
        setShowClosingStatusField(event.target.checked);
    };

    useEffect(() => {
        setShowClosingStatusField(record.showClosingStatus);
    }, [record]);

    useEffect(() => {
        const { getResponsibleForVerificationData,
            getSacopStatusForClosingData } = fetchDataService();
        Promise.all([
            getResponsibleForVerificationData(),
            getSacopStatusForClosingData(),
        ]).then(([responsibleForVerificationResponse,
            sacopStatusClosingResponse
        ]) => {
            setResponsibleForVerification(responsibleForVerificationResponse.data);
            setSacopStatusClosing(sacopStatusClosingResponse.data);
            setLoading(false);
        }).catch(error => {
            console.error('Error fetching data in Implementation:', error);
            setLoading(false);
        });
    }, []);

    const { dataUser, isLoadingUser } = useUser();

    if (loading) {
        return <Loading />;
    }

    if (isLoadingUser) {
        return <Loading />;
    }

    return (
        <SimpleForm
            toolbar={<FormToolbar edit={edit ? true : undefined} />}
            sx={{ mt: 3, mb: 3 }}
        >
            <Grid container lg={10} lgOffset={1} columnSpacing={2}>
                <Grid xs={12} sx={{ mb: 2 }}>
                    <Typography variant="h6">{formTitle}</Typography>
                </Grid>
                <Grid container md={12}>
                    <Grid xs={12} md={4}>
                        <TextInput
                            source="responsibleForImplementation.fullName"
                            label="resources.sacop.fields.responsibleForImplementationId"
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <DateInput
                            source="closeDate"
                            label="resources.sacop.fields.closeDate"
                            variant="outlined"
                            validate={required()}
                            helperText={false}
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                    <AutocompleteInput
                            source="responsibleForVerificationId"
                            label="resources.sacop.fields.responsibleForVerificationId"
                            choices={
                                dataUser
                                    ? dataUser.map((user) => ({
                                        id: user.id,
                                        name: user.fullName,
                                    }))
                                    : []
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={12} md={6} sx={{ pl: 3, mb: 3 }}>
                        <Grid container direction="column" spacing={1}>
                            <InputLabel>
                                <Typography variant="subtitle2" sx={{ fontSize: '0.875rem' }}>{labelEvidence}</Typography>
                            </InputLabel>
                            <Grid>
                                <FileField source="evidence.src" title="evidence.title" target="_blank" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={12}>
                        <TextInput
                            source="closingRemark"
                            label="resources.sacop.fields.closingRemark"
                            fullWidth
                            multiline
                        />
                    </Grid>
                    <Grid xs={12} md={2}>
                        <BooleanInput
                            autoFocus
                            source="showClosingStatus"
                            label="resources.sacop.fields.showClosingStatus"
                            fullWidth
                            onChange={handleBooleanInput}
                        />
                    </Grid>
                    {showClosingStatusField && (
                        <Grid xs={12} md={4}>
                            <SelectInput
                                source="closingStatusId"
                                label="resources.sacop.fields.closingStatusId"
                                fullWidth
                                // validate={required()}
                                choices={sacopStatusClosing?.map(item => ({ id: item.id, name: item.statusName }))}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </SimpleForm>
    );
};

export default ClosingOfSharesForm;