import spanishMessages from "ra-language-spanish";
import { TranslationMessages } from "react-admin";

const customEnglishMessages: TranslationMessages = {
  ...spanishMessages,
  ra: {
    configurable: {
      customize: "Customize",
    },
    action: {
      add: "Añadir",
      add_filter: "Añadir filtro",
      back: "Ir atrás",
      bulk_actions:
        "1 item seleccionado |||| %{smart_count} items seleccionados",
      cancel: "Cancelar",
      clear_input_value: "Limpiar valor",
      clear_array_input: "Limpiar valores",
      clone: "Clonar",
      close: "Cerrar",
      close_menu: "Cerrar menú",
      confirm: "Confirmar",
      create: "Crear",
      delete: "Eliminar",
      edit: "Editar",
      expand: "Expandir",
      export: "Exportar",
      list: "Listar",
      open_menu: "Abrir menú",
      refresh: "Refrescar",
      remove: "Borrar",
      remove_filter: "Borrar filtro",
      save: "Guardar",
      search: "Buscar",
      show: "Mostrar",
      sort: "Ordenar",
      undo: "Deshacer",
      unselect: "Deseleccionar",
    },
    auth: {
      auth_check_error: "Por favor inicie sesión para continuar",
      rememberme: "Recordarme",
      logout: "Cerrar Sesión",
      password: "Contraseña",
      sign_in: "Acceder",
      sign_in_error: "La autenticación falló, por favor, vuelva a intentarlo",
      user_menu: "Perfil",
      username: "Usuario",
    },
    boolean: {
      true: "Sí",
      false: "No",
      null: " ",
    },
    input: {
      file: {
        upload_several:
          "Arrastre algunos archivos para subir o haga clic para seleccionarlos.",
        upload_single:
          "Arrastre un archivo para subir o haga clic para seleccionarlo.",
      },
      image: {
        upload_several:
          "Arrastre algunas imagénes para subir o haga clic para seleccionarlas.",
        upload_single:
          "Arrastre alguna imagen para subir o haga clic para seleccionarla.",
      },
      references: {
        all_missing: "No se pueden encontrar datos de referencias.",
        many_missing:
          "Al menos una de las referencias asociadas parece no estar disponible.",
        single_missing: "La referencia asociada no parece estar disponible.",
      },
      password: {
        toggle_visible: "Ocultar contraseña",
        toggle_hidden: "Mostrar contraseña",
      },
    },
    message: {
      about: "Acerca de",
      are_you_sure: "¿Está seguro?",
      bulk_delete_content:
        "¿Seguro que quiere eliminar este %{name}? |||| ¿Seguro que quiere eliminar estos %{smart_count} items?",
      bulk_delete_title:
        "Eliminar %{name} |||| Eliminar %{smart_count} %{name} items",
      delete_content: "¿Seguro que quiere eliminar este item?",
      delete_title: "Eliminar %{name} #%{id}",
      details: "Detalles",
      error:
        "Se produjo un error en el cliente y su solicitud no se pudo completar",
      invalid_form:
        "El formulario no es válido. Por favor verifique si hay errores",
      loading: "La página se está cargando, espere un momento por favor",
      no: "No",
      not_found:
        "O bien escribió una URL incorrecta o siguió un enlace incorrecto.",
      yes: "Sí",
      unsaved_changes:
        "Algunos de sus cambios no se guardaron. ¿Está seguro que quiere ignorarlos?",
      clear_array_input: "Limpiar valores",
      empty: "No hay registros disponibles",
    },
    navigation: {
      next: "Siguiente",
      no_more_results:
        "El número de página %{page} está fuera de los límites. Pruebe la página anterior.",
      no_results: "No se han encontrado resultados",
      page_out_from_begin: "No puede ir antes de la página 1",
      page_out_from_end: "No puede ir después de la última página",
      page_out_of_boundaries: "Número de página %{page} fuera de los límites",
      page_range_info: "%{offsetBegin} - %{offsetEnd} de %{total}",
      page_rows_per_page: "Filas por página:",
      prev: "Anterior",
      skip_nav: "Saltar al contenido",
    },
    sort: {
      sort_by: "Ordenar por %{field} %{order}",
      ASC: "ascendente",
      DESC: "descendente",
    },
    notification: {
      bad_item: "Elemento incorrecto",
      canceled: "Acción cancelada",
      created: "Elemento creado",
      data_provider_error:
        "Error del proveedor de datos. Consulte la consola para más detalles.",
      deleted: "Elemento borrado |||| %{smart_count} elementos borrados.",
      http_error: "Error de comunicación con el servidor",
      item_doesnt_exist: "El elemento no existe",
      logged_out: "Su sesión ha finalizado, vuelva a conectarse.",
      updated:
        "Elemento actualizado |||| %{smart_count} elementos actualizados",
      i18n_error:
        "No se pudieron cargar las traducciones para el idioma especificado",
    },
    page: {
      create: "Crear %{name}",
      dashboard: "Dashboard",
      edit: "%{name} #%{id}",
      empty: "Sin %{name} todavía.",
      error: "Algo salió mal",
      invite: "¿Quiere agregar uno/a?",
      list: "Lista de %{name}",
      loading: "Cargando",
      not_found: "No encontrado",
      show: "%{name} #%{id}",
    },
    validation: {
      email: "Debe ser un correo electrónico válido",
      maxLength: "Debe contener %{max} caracteres o menos",
      maxValue: "Debe ser %{max} o menos",
      minLength: "Debe contener %{min} caracteres al menos",
      minValue: "Debe ser al menos %{min}",
      number: "Debe ser un número",
      oneOf: "Debe ser uno de: %{options}",
      regex: "Debe coincidir con un formato específico (regexp): %{pattern}",
      required: "Requerido",
    },
  },

  resources: {
    menu: {
      configuration: {
        name: "Configuración",
      },
      my_perfil: {
        name: "Mi Perfil",
      },
      security: {
        name: "Seguridad",
        mfa: {
          name: "Autenticación multifactor (MFA)",
          fields: {
            not_activated_description: "Actualmente NO tiene activada la autenticación multifactor (MFA), lo que añade una capa adicional de seguridad a su cuenta. Para activar MFA, simplemente presione el botón ''ACTIVAR MFA'' y siga las instrucciones para configurar una aplicación de autenticación como Authy Authenticator, Microsoft Authenticator, Google Authenticator u otra de su elección. Esta medida ayudará a proteger su cuenta contra accesos no autorizados, asegurando que solo usted pueda acceder a su información confidencial mediante un código generado por la aplicación MFA.",
            activated_description: "Actualmente tiene activada la autenticación multifactor (MFA), lo que añade una capa adicional de seguridad a su cuenta. Gracias a MFA, su cuenta está protegida contra accesos no autorizados, asegurando que solo usted pueda acceder a su información confidencial mediante un código generado por una aplicación de autenticación como Authy, Microsoft Authenticator, Google Authenticator u otra de su elección. MFA refuerza significativamente la seguridad de su cuenta, brindándole tranquilidad y protegiendo sus datos personales de posibles amenazas.",
            confirm_delete_description: "¿Está seguro que desea desactivar el MFA de su cuenta? Ingrese su contraseña para confirmar la acción.",
            setup: {
              init: "Por favor realice los siguientes pasos para activar la autenticación multifactor para su cuenta:",
              step_one: "Instale una aplicación de autenticación en su teléfono. Puede usar cualquier aplicación de autenticación, pero le recomendamos las siguientes:",
              step_two: "Cuando la aplicación se encuentre instalada en su teléfono, agregue una nueva cuenta y escanee el siguiente código QR:",
              step_three: "Una vez que la aplicación esté configurada y le otorgue un código de 6 dígitos, presione el botón ''siguiente''.",
              code_alternative: "Si tiene problemas para escanear el código, puede ingresar manualmente el siguiente código secreto:",
              google_auth: "Google Authenticator",
              microsoft_auth: "Microsoft Authenticator",
              authy_auth: "Authy Authenticator",
              code: {
                init_setup: "Para completar la configuración, por favor ingresa el código de 6 dígitos que aparece en tu aplicación de autenticación:",
                login: "Para iniciar sesión, por favor ingresa el código de 6 dígitos que aparece en tu aplicación de autenticación:",
                setup_success: "Autenticación multifactor agregada correctamente",
                invalid: "El código ingresado es inválido",
              }
            },
            recovery_codes: {
              name: "Códigos de recuperación",
              use_code: "Puedes eliminar la autenticación multifactor (MFA) utilizando un código de seguridad que fue generado al activar MFA en tu cuenta. Por favor, ten en cuenta que al usar este código, se desactivará el MFA y tu cuenta dependerá únicamente de tu contraseña para acceder.",
              question: "¿Perdiste acceso a tu dispositivo?",
              input_code: "Ingresa el código",
              invalid_code: "Código de recuperación no es correcto",
              use_code_btn: "Utilizar código de recuperación",
              first_message: "Estos son los códigos de recuperación que puedes utilizar en caso de perder acceso a tu dispositivo de autenticación. Es de suma importancia que guardes estos códigos en un lugar seguro, ya que",
              second_message: "solo se mostrarán en este momento.",
              third_message: "Sin ellos, podrías perder acceso a tu cuenta si no tienes tu dispositivo de autenticación. Almacénalos de manera segura para asegurarte de poder acceder a tu cuenta en cualquier circunstancia.",
              use_code_success: "Autenticación multifactor eliminada correctamente"
            },
            btns: {
              activate_mfa: "Activar MFA",
              deactivate_mfa: "Desactivar MFA",
              cancel_operation: "Cancelar",
              confirm_operation: "Confirmar",
              continue_operation: "Continuar"
            },
            notifications: {
              error: "Ha ocurrido un error inesperado",
              deactivate_error: "Error al desactivar el MFA",
              invalid_credentials: "Contraseña incorrecta",
              deactivate_success: "MFA desactivado con éxito",
              password_request: "Por favor ingrese su contraseña",
            }
          }
        }
      },
      my_company: {
        name: "Mi Compañía",
      },
      admin: {
        name: "Administración",
        user: {
          name: "Usuarios",
        },
        rol: {
          name: "Roles",
        },
      },
      report: {
        name: 'Informes',
        laboratory: {
          name: 'Inf. Laboratorio'
        },
        exports: {
          name: 'Inf. Exportación'
        }
      },
      backoffice: {
        name: "Backoffice",
        user: {
          name: "Usuarios",
        },
        sampling_location: {
          name: "Lugares",
        },
        rol: {
          name: "Roles",
        },
        company: {
          name: "Compañía",
        },
        sector: {
          name: "Rubro",
        },
        agent: {
          name: "Agentes",
        },
        customer: {
          name: "Clientes",
        },
        normative: {
          name: "Normativas",
        },
        localization: {
          name: "Centro de Costo",
        },
        laboratory: {
          name: "Laboratorios",
        },
        customs_house: {
          name: "Aduanas",
        },
        exporter: {
          name: "Exportadores",
        },
        instrument: {
          name: "Instrumentos",
        },
        port: {
          name: "Puertos",
        },
        requestForAction: {
          name: "Solicitud de Acción",
        },
        analysisOfShares: {
          name: "Análisis de Acciones",
        },
        implementationOfAction: {
          name: "Implementación de Acciones",
        },
        closingOfShares: {
          name: "Cierre de Acciones",
        },
        verificationEffectivenessAction: {
          name: "Verificación Eficacia Acción",
        },
        masterOfShares: {
          name: "Maestro de Acción",
        },
        sharesCommitee: {
          name: "Comité de Acción",
        },
        sacop: {
          name: "Dashboard",
        },
      },
      service: {
        name: "Servicios",
        police: {
          name: "Pólizas",
        },
        receipts: {
          name: "Recibos",
        },
        accidents: {
          name: "Siniestros",
        },
      },
      forms: {
        name: "Operaciones",
        weight_report_operation: { name: "Reg. Exportación - Inf. Peso" },
        laboratory: { name: "Reg. Laboratorio" },
      },
      sacop: {
        name: "SACOP",
        requestForAction: {
          name: "Solicitud de Acción",
        },
        closingOfShares: {
          name: "Cierre de Acciones",
        },
      },
    },
    auth: {
      rememberme: "Recordarme",
    },
    company: {
      name: "Compañía |||| Compañías",
      fields: {
        nro_fiscal: "Rut",
        name: "Nombre",
        phone: "Teléfono",
        email: "E-Mail",
        website: "Sitio web",
        direction: {
          name: "Dirección",
          state: "Región",
          city: "Ciudad",
          colony: "Comuna",
          street: "Calle",
        },
        contact: {
          name: "Contacto",
          area: "Área",
          email: "E-Mail",
          phone: "Teléfono",
        },
      },
    },
    sector: {
      name: "Rubro |||| Rubros",
      fields: {
        name: "Nombre",
      },
    },
    customer: {
      name: "Cliente |||| Clientes",
      fields: {
        nro_fiscal: "Rut",
        name: "Nombre",
        lastname: "Apellidos",
        phone: "Teléfono",
        mail: "E-Mail",
        birthday: "Fecha de Nacimiento",
        gender: "Género",
        civilStatus: "Estado Civil",
        educationalLevel: "Nivel de Educación",
        direction: {
          name: "Dirección",
        },
        card: {
          name: "Tarjeta Bancaria",
          type: "Tipo",
          issuer: "Emisor",
          bank: "Banco",
          titular: "Titular",
          ending: "Terminación",
          due: "Fecha de Vencimiento",
        },
      },
    },
    agent: {
      name: "Agente |||| Agentes",
      fields: {
        nro_fiscal: "Rut",
        name: "Nombre",
        lastname: "Apellidos",
        phone: "Teléfono",
        mail: "E-Mail",
        type: "Tipo",
        direction: {
          name: "Dirección",
          street: "Calle",
          colony: "Comuna",
          city: "Ciudad",
        },
      },
    },
    user: {
      name: "Usuario |||| Usuarios",
      title: "Usuario",
      fields: {
        nro_fiscal: "Rut",
        localization: "Localidad",
        name: "Nombre",
        lastname: "Apellidos",
        phone: "Teléfono",
        mail: "E-Mail",
        rol: "Rol",
        active: "Activo",
        repository: {
          name: "Repositorio de documentos",
          type: "Tipo",
          attachment: "Adjuntos",
        },
      },
    },
    rol: {
      name: "Rol |||| Roles",
      title: "Rol",
      permission: {
        name: "Permisos",
      },
      fields: {
        name: "Nombre",
        description: "Descripción",
        active: "Activo",
        permission: {
          name: "Permiso",
          module: "Modulo",
        },
      },
    },

    normative: {
      name: "Normativa |||| Normativas",
      title: "Normativa",
      structure: "Capítulos",
      fields: {
        code: "Código",
        name: "Nombre",
        description: "Descripción",
        version: "Version",
        active: "Activo",
        isrecord: "Permite Registros Operativos",
      },
      chapter: {
        fields: {
          code: "Código",
          name: "Nombre",
          active: "Activo",
          new_version: "Subir Versión",
          code_version: "Número de Versión",
          admin: {
            structure: {
              bed: "Administracion de Esctructura - BED"
            }
          }
        }
      }
    },

    localization: {
      name: "Localización |||| Localizaciones",
      title: "Localización",
      fields: {
        code: "Código",
        name: "Nombre",
        description: "Descripción",
        active: "Activo",
        activeName: "Estado",
        user: "Usuario Responsable",
        phone: "Teléfono",
        direction: {
          name: "Dirección",
          street: "Calle",
          colony: "Comuna",
          city: "Ciudad",
        },
        normative: {
          name: "Normativas",
        },
        normativeChain: "Normativas",
      },
    },
    export: {
      name: "Exportación |||| Exportaciones",
      title: "Exportación",
      files: "Archivos Adjuntos",
      fields: {
        customs_dus: "DUS Aduana",
        ahk_dus: "DUS AHK",
        dus_date: "Fecha DUS",
        dus_items: "Cantidad de Ítems",
        dus_item_number: "N° de Ítem",
        custom_house: "Aduana",
        weight_report_number: "N° Inf. Peso",
        weight_report_date: "Fecha Inf. Peso",
        exporter: "Exportador",
        remittee: "Consignatario",
        cochilco_contract: "Contrato Cochilco",
        contract_fee: "Cuota Contrato Cochilico",
        commercial_contract: "Contrato Comercial",
        export_port: "Puerto de Exportación",
        destination_port: "Puerto de Destino",
        motor_ship: "Motonave",
        testing_lab: "Laboratorio de Ensayo",
        testing_lab_reception_date: "Fecha de Recepción en LE",
        custom_lab_reception_date: "Fecha de Recepción en LQA",
        procedure_code: "Codigo y Versión del Procedimiento",
        weight_measuring_instrument: "Instrumento de Medición",
        weight_sample_place: "Lugar de Toma de Muestra y Control de Peso",
        sampling_system: "Sistema de Muestreo",
        humidity_sample_place: "Lugar Det. Humedad y Preparación Muestra",
        sample_start_date: "Inicio de Muestreo",
        sample_end_date: "Término de Muestreo",
        boarding_start: "Inicio del Embarque",
        boarding_end: "Término del Embarque",
        gross_weight: "Peso Bruto en Kg por DUS",
        tare_weight: "Peso Tara en Kg por DUS",
        dry_net_weight: "Peso Neto Seco en Kg por DUS",
        wet_net_weight: "Peso Neto Húmedo en Kg por DUS",
        humidity_percent: "Porcentaje de Humedad por DUS",
        total_gross_weight: "Peso Bruto Total en Kg del DUS",
        verification_list: "Lista de Verificación",
        weight_report: "Informe de Peso",
        dus_copy: "Copia DUS",
        customs_delivery_letter: "Carta de Entrega Aduana",
        other: "Otros",
        dus_store: "Bodega",
        active: "Activo",
      },
    },
    operation_registry: {
      name: "Registro de Operaciones",
      title: "Registro de Operaciones",
      fields: {
        dus: "DUS",
        dus_date: "Fecha DUS",
        dus_items: "Cantidad de Ítems",
        dus_item_number: "N° de Ítem",
        custom_house: "Aduana",
        weight_report_number: "N° Inf. Peso",
        weight_report_date: "Fecha Inf. Peso",
        exporter: "Exportador",
        testing_lab_reception_date: "Fecha de Recepción en LE",
        motorship: "Motonave",
        sample_start_date: "Inicio de Muestreo",
        sample_end_date: "Término de Muestreo",
      },
    },
    laboratory_registry: {
      name: "Laboratorio || Laboratorios",
      title: "Registro de Op. de Laboratorio",
      fields: {
        oi_name: "Nombre del OI",
        element_sample: {
          metric: "Métrica",
          symbol: "Símbolo Químico",
          value: "Valor",
        },
        outsourced_analytes: "Analitos Externalizados",
        payable_element: "Elementos Pagables",
        penalizable_element: "Elementos Penalizables",
        outsourced_laboratory: "Laboratorio Subcontratado",
        quality_report: {
          name: "Informe de Calidad",
          fields: {
            file: "Archivo Infome de Calidad",
            number: "N° Informe de Calidad",
            date: "Fecha Infode de Calidad",
          },
        },
        active: "Activo",
      },
    },

    laboratory: {
      name: "Laboratorio |||| Laboratorios",
      title: "Laboratorio",
      fields: {
        name: "Nombre",
        current_resolution: "Resolución actual",
        accreditation_file: "Archivo de Acreditación",
        active: "Activo",
        type: {
          name: "Tipo",
          internal: "Interno",
          outsourced: "Subcontratado",
        },
      },
    },
    customs_house: {
      name: "Aduana |||| Aduanas",
      title: "Aduana",
      fields: {
        name: "Nombre Aduana",
        code: "Código",
        contact_name: "Nombre de Contacto",
        contact_phone: "Número de Contacto",
        contact_email: "Correo de contacto",
        ahk_contact: "Contacto AHK",
        address: "Dirección",
        active: "Activa",
      },
    },
    exporter: {
      name: "Exportador |||| Exportadores",
      title: "Exportador",
      fields: {
        name: "Nombre Exportador",
        taxpayer_reference: "RUT Exportador",
        contact_email: "Correo de Contacto",
        contact_phone: "Teléfono de Contacto",
        ahk_contact: "Contacto AHK",
      },
      errors: {
        taxpayer_reference: "El RUT ya está en uso",
      },
    },
    instrument: {
      name: "Instrumento |||| Instrumentos",
      title: "Instrumento",
      fields: {
        name: "Nombre",
        code: "Código Interno",
        brand: "Marca",
        model: "Modelo",
        verificationPeriod: "Próxima Verificación en",
        costCenter: "Centros de Costos",
        require_calibration: "Requiere de Calibración",
        require_verification: "Requiere de Verificación",
        active: "Activo",
        calibration: {
          date: "Fecha de Calibración",
          calibration_period: "Periodo de Calibración",
          verification_period: "Periodo de Verificación",
          calibrated_by: "Calibrado por",
          responsible: "Responsable de Calibración",
          localization: "Oficina",
          certificate: "Certificado de Calibración"
        },
      },
    },
    port: {
      name: "Puerto |||| Puertos",
      title: "Puerto",
      fields: {
        name: "Nombre",
        code: "Código",
        cost_center: "Centro de Costo",
        active: "Activo",
      },
    },
    weight_report_operation: {
      name: "Registro de Exportación - Inf. Peso |||| Registro de Exportaciones - Inf. Peso",
      title: "Registro de Exportación - Inf. Peso",
      fields: {
        cochilco_contract: {
          number: "Número de Contrato COCHILCO",
          fee: "Cuota de Contrato COCHILCO",
        },
        commercial_contract: { number: "Número de Contrarto Comercial" },
        export_port: "Puerto de Exportación",
        destination_port: "Puerto de Destino",
        testing_lab: "Laboratorio de Ensayo",
        custom_lab_reception_date:
          "Fecha de Recepción en Laboratorio Químico de Aduanas",
        procedure_code: "Código y Versión del Procedimiento",
        weight_measuring_instrument: "Instrumendo de Medición de Peso",
        weight_sample_place: "Lugar de Toma de Muestra de Control de Peso",
        sampling_system: "Sistema de Muestreo",
        humidity_sample_place: "Lugar Det. Humedad y Preparación Muestra",
        boarding_start: "Inicio del Embarque",
        boarding_end: "Término del Embarque",
        gross_weight: "Peso Bruto en Kg por DUS",
        tare_weight: "Peso Tara en Kg por DUS",
        wet_net_weight: "Peso Neto Húmedo en Kg por DUS",
        dry_net_weight: "Peso Neto Seco en Kg por DUS",
        humidity_percent: "Porcentaje de Humedad por DUS",
        total_gross_weight: "Peso Bruto Total en Kg del DUS",
        weight_report: {

          number: "Número de Informe de Peso",
          date: "Fecha de Informe de Peso",
          file: "Archivo de Informe de Peso",
        },
        active: "Activo",
        stores: {
          name: "Bodegas",
          number: "Número de Bodega",
          wet_net_weight: "Peso Neto en Kg por Bodega",
        },
        verification_list: "Lista de Verificación",
        dus_copy: "Copia DUS",
        custom_delivery_letter: "Carta de Entrega Aduana",
        other_files: "Otros",
      },
    },
    sampling_location: {
      name: "Lugar de Toma de Muestra |||| Lugares de Toma de Muestra",
      title: "Lugar de Toma de Muestra",
      fields: {
        name: "Nombre",
        active: "Activo",
      },
    },
    commands: {
      title: "%{module} %{reference}",
    },
    sacop: {
      name: "Solicitud de Acción",
      titles: {
        detail: { title: "Solicitud de Acciones Correctivas y Preventivas (SACOP)" },
        requestForAction: {
          title: "Solicitud de Acción",
          shortTitle: "Solicitud",
        },
        analysisOfShares: {
          title: "Análisis de Acciones",
          shortTitle: "Análisis",
        },
        implementationOfAction: {
          title: "Implementación de Acciones",
          shortTitle: "Implementación",
        },
        closingOfShares: {
          title: "Cierre de Acciones",
          shortTitle: "Cierre",
        },
        verificationEffectiveness: {
          title: "Verificación Eficiencia Acción",
          shortTitle: "Verificación",
        },
        verificationEffectivenessAction: {
          title: "Verificación Eficiencia Acción",
          shortTitle: "Verificación",
        },
        masterOfShares: { title: "Maestro de Acciones" },
        sacop: { title: "Sacop Dashboard" },
      },
      subtitles: {
        firstSection: { title: "IDENTIFICACIÓN DE SOLICITUD" },
        secondSection: { title: "ANÁLISIS DEL PROBLEMA" },
        thirdSection: { title: "IMPLEMENTACIÓN" },
        fourthSection: { title: "CIERRE" },
        fifthSection: { title: "VERIFICACIÓN" },
      },
      actions: {
        requestForAction: {
          create: { title: "Crear solicitud de acción" },
          edit: { title: "Editar solicitud de acción" },
        },
        analysisOfShares: {
          edit: { title: "Editar Análisis de Acción" },
        },
        implementationOfAction: {
          edit: { title: "Editar Implementación de Acción" },
        },
        closingOfShares: {
          edit: { title: "Editar Cierre de Acción" },
        },
        verificationEffectivenessAction: {
          edit: { title: "Editar Verificación Eficacia Acción" },
        },
        masterOfShares: {
          edit: { title: "Editar Maestro de Acción" }
        }
      },
      fields: {
        //1.- SOLICITUD DE ACCIÓN....
        id: "Solicitud Nro",
        typeOfActionId: "Tipo de Acción",
        actionNumber: "Numero de Acción",
        dateRequestAction: "Fecha Emisión",
        expirationDateAction: "Fecha Vencimiento",
        correctiveActionTypeId: "Tipo de Acción Correctiva",
        generator: "Generador",
        typeOfGeneratingEntityId: "Tipo de Entidad Generadora",
        generatingEntity: "Entidad Generadora",
        authorityId: "Autoridad",
        localizationId: "Centro de Costo",
        exporterId: "Exportador",
        freeText: "Texto Libre",
        motiveId: "Motivo",
        whoReports: "Quién Reporta",
        responsibleForAnalysisId: "Responsable de Análisis", //>-
        regulationId: "Normativa, Reglamento u Otro",
        problemDescription: "Descripción del Problema",
        immediateActions: "Acción Inmediata",
        textualEvidence: "Evidencia Textual",
        evidence: "Evidencia",
        //1.- SOLICITUD DE ACCIÓN....

        //2.- ANÁLISIS DEL PROBLEMA....
        visibleCause: "Causa Visible",
        causeOrigin: "Causa Origen",
        rootCause: "Causa Raíz",
        correctiveActions: "Acción Correctiva / Preventiva Definitiva",
        impactId: "Impacto",
        valorizationId: "Valorización",
        valuationAmount: "Monto Valorización",
        dateOfFormationOfWorkTeam: "Fecha de la Formación de Equipo",
        analysisDate: "Fecha de Análisis",
        workTeamMembers: "Miembros del Equipo",
        responsibleForImplementationId: "Responsable de la Implementación", //>-
        evaluationResult: "Resultado de Evaluación",
        //2.- ANÁLISIS DEL PROBLEMA....

        //3.- IMPLEMENTACIÓN....
        implementationDate: "Fecha Implementación",
        // evidence: "Evidencia",
        responsibleForClosingId: "Responsable de Cierre", //>-
        //3.- IMPLEMENTACIÓN....

        //4.- CIERRE....
        closeDate: "Fecha de Cierre",
        closingRemark: "Observación de Cierre",
        responsibleForVerificationId: "Responsable de Verificación",
        closingStatusId: "Estado Cierre",
        showClosingStatus: "Mostrar Estado",
        //4.- CIERRE....

        //5.- VERIFICACIÓN....
        verificationDate: "Fecha de Verificación",
        verificationObservations: "Observaciones Verificación",
        verificationStatusId: "Estado Verificación",
        showVerificationStatus: "Mostrar Estado",
        //5.- VERIFICACIÓN.... 

        //*
        sendToResponsibleForAnalysis: "Enviar al Responsable de Análisis",
        sendToResponsibleForImplementation: "Enviar al Responsable de Implementación",
        sendToResponsibleForClosingOfActions: "Enviar al Responsable de Cierre de Acciones",
        sendToResponsibleForVerificationEffectivenessAction: "Enviar al Responsable de Verificación",
        dateLastUpdated: "Fecha última actualización",
        responsible: "Responsable",
        closed: "Cerrado",
        rejected: "Rechazado",
        annulled: "Anulado",
        actionStatus: "Estado Acción",
        verificationStatus: "Estado Verificación",
        correctiveActionType: {
          id: "Id",
          correctiveActionName: "Tipo",
        },
        motive: {
          id: "Id",
          motiveName: "Origen",
        },
        typeOfGeneratingEntity: {
          id: "Id",
          nameOfGeneratingEntity: "Entidad generadora",
        },
      },
    },

    closingOfShares: {
      name: "Cierre de Acciones",
    },
    requestForAction: {
      name: "Solicitud de Acción"
    },
    analysisOfShares: {
      name: "Análisis de Acciones"
    },
    implementationOfAction: {
      name: "Implementación de Acciones"
    },
    verificationEffectiveness: {
      name: "Verificación Eficiencia Acción"
    },
    verificationEffectivenessAction: {
      name: "Verificación Eficiencia Acción"
    },
    masterOfShares: { 
      name: "Maestro de Acciones" 
    },

    sharesCommitee: {
      name: "Comité de Acción" 
    },

    dashboard: {
      title: "Dashboard",
      welcome: {
        title: "Bienvenido",
        subtitle: "Al sistema de Control de AHK"
      },
      usersWithoutDocumentation: "Usuarios sin Documentación",
      calibrationEquipmentDueExpire: "Equipos de Calibración por vencer",
      actionRequestPending: "Solicitud de Acción Pendientes",
      pendingExportRecord: "Registros de Exportación Pendientes",
    }
  },
};

export default customEnglishMessages;
